import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { pxToRem } from 'theme/utils';
import { Header, PageWrapper } from 'components';
import { Link } from 'react-router-dom';

import colors from 'theme/colors';
const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        marginTop: theme.spacing(2.5),
        height: 390,
        backgroundColor: colors.purpleAxis,
        color: colors.white,
        borderBottom: `solid 10px ${colors.purpleLabel}`,
    },
    title: {
        marginBottom: 30,
        paddingTop: 40,
        color: colors.white,
        fontSize: pxToRem(20),
        fontFamily: "'Inter SemiBold', sans-serif",
        [theme.breakpoints.up('sm')]: {
            paddingTop: 90,
            fontSize: pxToRem(30),
        },
    },
    subtitle: {
        fontSize: 18,
        fontFamily: "'Inter', sans-serif",
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(22),
        },
    },
    button: {
        marginTop: 25,
        backgroundColor: theme.colors.white,
        color: theme.colors.navy,
        [theme.breakpoints.up('sm')]: {
            marginTop: 50,
        },

        '&:hover, &:active': {
            backgroundColor: colors.navy,
            color: theme.colors.white,
        },

        '@media (hover: none)': {
            '&.MuiButton-contained:active, &.MuiButton-contained:hover': {
                backgroundColor: theme.colors.white,
                color: theme.colors.navy,
            },
        },
    },
}));

const Cta: FC = () => {
    const classes = useStyles();
    const intl = useIntl();
    return (
        <section data-qa="advisor-cta" className={classes.root}>
            <PageWrapper>
                <Header type="section" className={classes.title}>
                    <FormattedMessage id="advisor.home.cta.title" />
                </Header>
                <div className={classes.subtitle}>
                    <FormattedMessage id="advisor.home.cta.subtitle" />
                </div>
                <div>
                    <Button
                        component={Link}
                        to="/advisor/create"
                        data-analytics-label="trackLink:buttonClick"
                        data-analytics-id={intl.formatMessage({
                            id: 'advisor.home.cta.ctaLabel',
                        })}
                        data-analytics-placement="Button:bottom-center"
                        className={classes.button}
                        variant="contained"
                    >
                        <FormattedMessage id="advisor.home.cta.ctaLabel" />
                    </Button>
                </div>
            </PageWrapper>
        </section>
    );
};

export default Cta;
