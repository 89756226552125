import { FC } from 'react';
import { clsx } from 'clsx';

/* material */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        margin: '0 20px 0',
        [theme.breakpoints.up('md')]: {
            margin: '0 auto 0',
            maxWidth: `${theme.breakpoints.values['md']}px`,
            width: '100%',
        },
    },
}));

const CreateContentWrapper: FC<React.HTMLAttributes<HTMLDivElement>> = ({
    children,
    className,
    ...others
}) => {
    const classes = useStyles();
    return (
        <div {...others} className={clsx(classes.contentWrapper, className)}>
            {children}
        </div>
    );
};

export default CreateContentWrapper;
