let colors = {
    blue: '#176CD9',
    navy: '#000F40',
    navyTint1: '#6C7491',
    navyTint2: '#F4F5F9',
    deepNavy: '#000926',
    purple: '#8F79FF',
    purpleLight: '#CCD0FF', //Use for purple text on dark backgrounds
    purpleDark: '#7360D3', // Use for purple text on light backgrounds
    purpleAxis: '#4938A1', // -> use for axis
    purpleLabel: '#B2B2FF', // -> use for text labels
    black: '#000000',
    white: '#FFFFFF',
    turquoise: '#00AEA9',
    turquoise30: '#52CABB',
    darkTurquoise: '#008E77',
    teal: '#52CABB',
    //typescript require
    blueGradient: '',
    redGradient: '',
    purpleGradient: '',
    rainbowGradient: '',
    valencia: '#D63C3C',
    mulberry: '#C5558A',
    scrollBarColor: '#59609F',
    red: '#D63C3C',
    cobalt: '#005F9E',
    whiteSmoke: '#f7f7f7',
    darkGray: '#b2b2b2',
    midGray: '#E5E5E5',
    pacificBlue: '#009CDC',
    mediumRedViolet: '#B42573',
    bluewood: '#010a24',
    gainsboro: '#e5e5e5',
    sherpaBlue: '#002b49',
    vibrantRed: '#D91943',
    gray: '#7f7f7f',
    green: '#188C0C',
    sapphire: '#005F9E',
    alertRed: '#D91943',
};

colors = {
    ...colors,
    blueGradient: `linear-gradient(180deg, ${colors.navy} 0%, ${colors.turquoise30} 100%)`,
    redGradient: `linear-gradient(180deg, ${colors.valencia} 0%, ${colors.mulberry} 100%)`,
    purpleGradient: `linear-gradient(180deg, ${colors.purpleAxis} 0%, ${colors.purple} 100%)`,
    rainbowGradient: `linear-gradient(90deg, ${colors.valencia} 0%, ${colors.purple} 60%, ${colors.turquoise30} 100%);
`,
};

export type colorType = typeof colors;
export default colors;
