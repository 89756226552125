export const TITLE_HOMEPAGE = 'Retirement Plan Insights';
export const TITLE_PRIVACY = 'Privacy Policy';
export const TITLE_BENCHMARKINGASSESSMENT =
    'Retirement Plan Benchmarking Assessment';
export const TITLE_BENCHMARKINGSUMMARY =
    'Retirement Plan Benchmarking Assessment Summary';
export const TITLE_PAGENOTFOUND = 'Page Not Found';
export const TITLE_HOW_TO_USE_RPI = 'How to use RetirementPlanInsights';
export const TITLE_CREATE_WEBSITE = 'Create your websites';
export const TITLE_CREATE_WEBSITE_ADVISOR_INFO =
    'Create your websites - Advisor Information';
export const TITLE_CREATE_WEBSITE_PLAN_SPONSOR_INFO =
    'Create your websites - Plan Sponsor Information';
export const TITLE_CREATE_WEBSITE_REVIEW = 'Create your websites - Review';
export const TITLE_CREATE_WEBSITE_SUCCESS = 'Create your websites - Success';
export const TITLE_MANAGE_WEBSITE = 'Manage websites';
export const TITLE_CREATE_WEBSITE_LOGIN = 'Create your websites - login';
export const TITLE_CREATE_WEBSITE_LOGOUT = 'Create your websites - logout';
export const TITLE_EMAIL_PREFERENCES = 'Email Preferences';
export const TITLE_UNSUBSCRIBE = 'Unsubscribe';
