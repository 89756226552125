import { FC } from 'react';
import { useSelector } from 'react-redux';

/* material */
import { makeStyles, useMediaQuery, Theme } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

/* component */
import Step from './BlockStep';
import InlineStep from './InlineStep';

/* state */
import { AppState } from 'store/store/configureStore';
import { steps, Step as tStep } from '../config';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '25px 0 20px',

        [theme.breakpoints.up('sm')]: {
            margin: '40px 0',
        },
    },
    blockSteps: {
        backgroundColor: theme.colors.navyTint2,
        display: 'flex',
        justifyContent: 'space-between',

        padding: '18px 0 14px',
        width: '100%',
    },
}));

const StepsBanner: FC = () => {
    const classes = useStyles();
    const activeStep = useSelector(
        (state: AppState) => state.createWebsite.step,
    );
    const isDesktop = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up('sm'),
    );

    return (
        <div className={classes.root}>
            {isDesktop ? (
                <div className={classes.blockSteps}>
                    {steps.map((step: tStep, idx: number) => {
                        const number = idx + 1;
                        const isLast = number === steps.length;
                        const icon = isLast ? <CheckCircleIcon /> : null;
                        return (
                            <Step
                                key={idx}
                                icon={icon}
                                isArrowVisible={!isLast}
                                number={number}
                                step={activeStep}
                                label={step.label}
                            />
                        );
                    })}
                </div>
            ) : (
                <InlineStep step={activeStep} steps={steps} />
            )}
        </div>
    );
};

export default StepsBanner;
