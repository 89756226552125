import { FC } from 'react';

/* material */
import { makeStyles } from '@material-ui/core';

import { Typography } from '@material-ui/core';

/* components */
import { LinkButton } from 'components';

const useStyles = makeStyles((theme) => ({
    root: {
        borderBottom: `solid 1px ${theme.colors.navyTint1}`,
        marginBottom: 0,
        padding: '6px 0 14px',
        width: '100%',
    },
}));

interface NotFoundOptionProps {
    label: string;
}

const NotFoundOption: FC<NotFoundOptionProps> = ({ label }) => {
    const classes = useStyles();
    return (
        <LinkButton
            data-analytics-id={label}
            data-analytics-label="trackLink:buttonClick"
            data-analytics-placement="Plan Sponsor Search:center-center"
            data-qa="link-button"
            classes={{ root: classes.root }}
            component="button"
        >
            <Typography variant="body1" component="span">
                {label}
            </Typography>
        </LinkButton>
    );
};

export default NotFoundOption;
