export const saveToLocalStorage = (key: string, value: any): boolean => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
        return true;
    } catch (e) {
        return false;
    }
};

export const getLocalStorage = (key: string): any => {
    try {
        const localState = localStorage.getItem(key);
        if (localState === null) return '';
        return JSON.parse(localState);
    } catch (e) {
        return {};
    }
};
