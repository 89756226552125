import { FC } from 'react';

/* material */
import { makeStyles } from '@material-ui/core';

/* type */
import { Step } from '../config';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.colors.navyTint2,
        display: 'flex',
        fontSize: 16,
        width: '100%',
        padding: '11px 20px 13px',
    },
    label: {
        color: theme.colors.purpleDark,
        fontWeight: 600,
        marginRight: 10,
    },
}));

interface InlineStepProps {
    step: number;
    steps: Step[];
}

const InlineStep: FC<InlineStepProps> = ({ step, steps }) => {
    const classes = useStyles();
    const isLastStep = step === steps.length;
    const stepConfig = steps[step - 1];

    if (!stepConfig) return null;

    return isLastStep ? null : (
        <div className={classes.root}>
            <div className={classes.label}>
                Step {step} of {steps.length}:
            </div>
            <div>{stepConfig.label}</div>
        </div>
    );
};

export default InlineStep;
