import { FC, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { asyncLoadContainer } from 'utils/asyncLoad';

/* actions */
import {
    setActiveStep,
    setUrlSlug,
} from 'store/actionCreators/createWebsiteActionCreator';

/* material */
import { makeStyles, Typography } from '@material-ui/core';

/* components */
import {
    CreateContentWrapper,
    FooterNav,
    RedirectToStart,
} from '../../Components';
import { Loader } from 'components';

/* constants */
import { TITLE_CREATE_WEBSITE_PLAN_SPONSOR_INFO } from 'constants/pageTitles';
import {
    AEM_NODE_ADVISOR_PLAN_SPONSOR_INFO,
    SELF_SERVICE_START,
    SELF_SERVICE_STEP,
    TOOL_ADVISOR_CREATE_WEBSITE,
    TOOL_ADVISOR_CREATE_WEBSITE_MAKE_ANOTHER,
    TOOL_ADVISOR_CREATE_WEBSITE_NEW,
} from 'analytics/constants';

/* state */
import { AppState } from 'store/store/configureStore';

/* services */
import {
    generateUrlSlug,
    GenerateUrlSlugResponse,
} from 'store/services/advisorServices';

/* analytics */
// import { analyticsAdvisorMultiStepEvent } from 'analytics/analyticsMultiStepEvent';
import { multiStepEvent } from 'analytics/index';

const Form = asyncLoadContainer('Advisor/Create/PlanSponsorInfo/Form');
const Search = asyncLoadContainer('Advisor/Create/PlanSponsorInfo/Search');

const ACTIVE_STEP = 2;

export const getStepLabel = (isCreateAnother: boolean, activeStep: number) => {
    return {
        label: isCreateAnother
            ? TOOL_ADVISOR_CREATE_WEBSITE_MAKE_ANOTHER
            : TOOL_ADVISOR_CREATE_WEBSITE_NEW,
        step: `Step${isCreateAnother ? activeStep - 1 : activeStep}`,
    };
};

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: 30,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 30,
        },
    },
    searchWrapper: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 30,
        },
    },
}));

const PlanSponsorInfo: FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        displayName,
        customUrl,
        isFlowActive,
        officialName,
        isCreateAnother,
    } = useSelector((state: AppState) => state.createWebsite, shallowEqual);
    const { data, isLoading, error } = useQuery<GenerateUrlSlugResponse>(
        [`generate-url-slug`, officialName, customUrl],
        () => generateUrlSlug(displayName, officialName),
        {
            enabled: !!officialName && !!!customUrl,
        },
    );

    useEffect(() => {
        if (isFlowActive) {
            const { step } = getStepLabel(isCreateAnother, ACTIVE_STEP);
            multiStepEvent({
                analyticsAppName: AEM_NODE_ADVISOR_PLAN_SPONSOR_INFO,
                eventName: isCreateAnother
                    ? SELF_SERVICE_START
                    : SELF_SERVICE_STEP,
                name: `${step}: ${TOOL_ADVISOR_CREATE_WEBSITE} - Plan Sponsor Info`,
                sessionSettings: 'Session',
            });
            // analyticsAdvisorMultiStepEvent({
            //     label,
            //     action: isCreateAnother
            //         ? SELF_SERVICE_START
            //         : SELF_SERVICE_STEP,
            //     AEMNodePath: AEM_NODE_ADVISOR_PLAN_SPONSOR_INFO,
            //     value: `${step}: ${TOOL_ADVISOR_CREATE_WEBSITE} - Plan Sponsor Info`,
            //     result: '',
            // });
        }
    }, [isCreateAnother, isFlowActive]);

    useEffect(() => {
        dispatch(setActiveStep(ACTIVE_STEP));
    }, [dispatch]);

    useEffect(() => {
        if (!data?.urlSlug) return;
        dispatch(setUrlSlug(data.urlSlug));
    }, [data?.urlSlug, dispatch]);

    if (error) {
        console.error(error);
    }

    if (!isFlowActive) return <RedirectToStart />;

    return (
        <div data-qa="advisor-plan-sponsor-info">
            {isLoading && <Loader />}
            <Helmet>
                <title>{TITLE_CREATE_WEBSITE_PLAN_SPONSOR_INFO}</title>
            </Helmet>
            <CreateContentWrapper className={classes.header}>
                <Typography component="h2" variant="h3">
                    <FormattedMessage id="advisor.create.planSponsorInformation" />
                </Typography>
            </CreateContentWrapper>
            {customUrl ? (
                <Form />
            ) : (
                <CreateContentWrapper className={classes.searchWrapper}>
                    <Search />
                    <FooterNav activeStep={ACTIVE_STEP} />
                </CreateContentWrapper>
            )}
        </div>
    );
};

export default PlanSponsorInfo;
