import { FC, useState, useEffect } from 'react';
import { Tokens } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { FormattedMessage, useIntl } from 'react-intl';
import parse from 'html-react-parser';
import { useLocation } from 'react-router';

/* material */
import { Typography, makeStyles } from '@material-ui/core';

/* components */
import { Loader, SignInWidget } from 'components';

/* routes */
import { ADVISOR_LOGIN_PATH } from 'routes/AdvisorRoutes';

/* services */
import { refreshFinancialAdvisorDataFromOkta } from 'store/services/advisorServices';

/* types */
import { CustomClaims } from '../Create/AdvisorInfo';
import { EventContext, EventErrorContext } from '@okta/okta-signin-widget';

/* utils */
import { saveToLocalStorage } from 'utils/localStorage';
import { createCookie } from 'utils/cookie';
import {
    loginStarted,
    loginFailure,
    loginCompleted,
    pageLoadStartedEvent,
    pageLoadCompletedEvent,
} from 'analytics';

const useStyles = makeStyles((theme) => ({
    subTitle: {
        color: theme.colors.purpleDark,
        fontWeight: 600,
        margin: '20px 0 30px',
    },
    links: {
        marginBottom: 30,

        '& > h6': {
            color: theme.colors.navy,
            fontWeight: 600,
            marginBottom: 10,
        },

        '& a': {
            color: theme.colors.blue,
            textDecoration: 'none',

            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
}));

const OktaLogin: FC = () => {
    const { oktaAuth } = useOktaAuth();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const classes = useStyles();
    const intl = useIntl();
    const location = useLocation();

    useEffect(() => {
        // analyticsAdvisorPageViewEvent(AEM_NODE_LOGIN_START);
        pageLoadStartedEvent('login > start', 'start', 'RPIAdvisorPortal');
        loginStarted({
            processStepName: 'login',
            processValueName: 'login',
            siteLoginStatus: 'Login start',
            loginMethod: 'RPI Advisor Portal Login',
        });
    }, []);

    const onSuccess = async (tokens: Tokens) => {
        setIsLoading(true);
        const accessToken = tokens.accessToken;
        const uuid = (accessToken?.claims as CustomClaims).uuid;
        const token = accessToken?.accessToken as string;
        const aeid = tokens.idToken?.claims.preferred_username;
        const redirect =
            location.state &&
            (location.state as { [key: string]: any }).loginRedirect;

        try {
            const res = await refreshFinancialAdvisorDataFromOkta(uuid, token);
            const companyWebsite = res.firmWebUrl || '';
            const designation = res.designation || '';
            const jobTitle = res.title || '';
            const phoneNumber = res.phoneNumber || '';

            aeid && createCookie('AEID', aeid.replace(/^[a-zA-Z]/, ''));
            createCookie('loginMethodType', 'RPI Advisor Portal Login', {
                domain: '.retirementplaninsights.com',
                sameSite: 'None',
                secure: true,
            });
            saveToLocalStorage('advisor', {
                companyWebsite,
                designation,
                jobTitle,
                phoneNumber,
            });
            setIsLoading(false);
            loginCompleted({
                processStepName: 'Login Completed',
                processValueName:
                    'Step 2: Verification - Sample Site Login Multistep',
                siteLoginStatus: 'Login Completed',
                loginMethod: 'Login Completed',
            });
            pageLoadCompletedEvent();
            await oktaAuth.handleLoginRedirect(tokens, redirect);
        } catch (error) {
            console.error('Unable to refresh FA: ', error);
            setIsLoading(false);
            //analyticsAdvisorPageViewEvent(AEM_NODE_LOGIN_FAILURE);
            await oktaAuth.signOut({
                postLogoutRedirectUri: `${window.location.origin}${ADVISOR_LOGIN_PATH}?refresh=false`,
            });
        }
    };

    const onLoginError = (
        _context: EventContext,
        error: EventErrorContext | undefined,
    ) => {
        console.error('Sign in error: ', error?.message);
        pageLoadStartedEvent('login > failure', 'failure', 'RPIAdvisorPortal');
        loginFailure({
            processStepName: 'Login Failure',
            processValueName:
                'Step 2: Verification - Sample Site Login Multistep',
            siteLoginStatus: 'Login Failure',
            loginMethod: 'Login Failure',
        });
        pageLoadCompletedEvent();
    };

    const onError = (err: Error) => {
        console.log('Sign-in widget in error:', err);
    };

    const recoverUsernameTextLink = parse(
        intl.formatMessage({ id: 'login.recoverUsername' }),
    );
    const recoverPasswordTextLink = parse(
        intl.formatMessage({ id: 'login.recoverPassword' }),
    );
    const registerTextLink = parse(
        intl.formatMessage({ id: 'login.registerAccount' }),
    );

    return (
        <>
            {isLoading && <Loader />}
            <div>
                <Typography variant="h3">
                    <FormattedMessage id="login.required" />
                </Typography>
                <Typography
                    variant="body1"
                    className={classes.subTitle}
                    component="h6"
                >
                    <FormattedMessage id="login.enterCredentials" />
                </Typography>
                <div>
                    <SignInWidget
                        onSuccess={onSuccess}
                        onError={onError}
                        onLoginError={onLoginError}
                    />
                </div>
                <div className={classes.links}>
                    <Typography variant="body1" component="h6">
                        <FormattedMessage id="login.forgetUsername" />
                    </Typography>
                    <Typography variant="body1" component="div">
                        {recoverUsernameTextLink}
                    </Typography>
                </div>
                <div className={classes.links}>
                    <Typography variant="body1" component="h6">
                        <FormattedMessage id="login.forgetPassword" />
                    </Typography>
                    <Typography variant="body1" component="div">
                        {recoverPasswordTextLink}
                    </Typography>
                </div>
                <div className={classes.links}>
                    <Typography variant="body1" component="h6">
                        <FormattedMessage id="login.onlineAccount" />
                    </Typography>
                    <Typography variant="body1" component="div">
                        {registerTextLink}
                    </Typography>
                </div>
            </div>
        </>
    );
};

export default OktaLogin;
