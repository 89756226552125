import { FC, useEffect, useRef } from 'react';
import OktaSignIn, { EventCallback } from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import './signInWidget.css';
import widgetConfig from './config/widgetConfig';
import { Tokens } from '@okta/okta-auth-js';

interface SignInWidgetProps {
    onSuccess: (tokens: Tokens) => void;
    onError: (err: any) => void;
    onLoginError: EventCallback;
}

const SignInWidget: FC<SignInWidgetProps> = ({
    onSuccess,
    onError,
    onLoginError,
}) => {
    const widgetRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!widgetRef.current) {
            return;
        }

        const widget = new OktaSignIn(widgetConfig);
        widget.on('afterError', onLoginError);
        widget
            .showSignInToGetTokens({
                el: '#widget',
            })
            .then(onSuccess)
            .catch(onError);

        return () => widget.remove();
    }, [onSuccess, onError, onLoginError]);

    return <div ref={widgetRef} id="widget" />;
};

export default SignInWidget;
