import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';

/* material */
import { makeStyles, Typography } from '@material-ui/core';

/* components */
import { Select, PageWrapper, Loader } from 'components';
import ContentSection, {
    AdvisorStory,
    AdvisoryStoryFragment,
} from './ContentSection';

/* constants */
import { contentFragmentUrl, AEMMODELS } from 'constants/aem';

type AdvisorStoryResponse = {
    articles: AdvisoryStoryFragment[];
};

const useStyles = makeStyles((theme) => ({
    storiesWrapper: {
        backgroundColor: theme.colors.navyTint2,
    },
    storiesSectionHeader: {
        padding: '30px 0',
        textAlign: 'center',

        [theme.breakpoints.up('sm')]: {
            padding: '60px  0 40px',
        },
    },
    storiesContentWrapper: {
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    screenshot: {
        border: `solid 2px ${theme.colors.purple}`,
        borderRadius: '5px',
        boxShadow: theme.nextStepModal.step.boxShadow,
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            border: 'none',
            boxShadow: 'none',
            maxWidth: 550,
        },
    },
    screenshotWrapper: {
        backgroundColor: theme.colors.white,
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            backgroundColor: 'transparent',
            width: '45%',
        },
    },
    dropdownWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 16,
    },
    contentSectionWrapper: {
        [theme.breakpoints.up('sm')]: {
            width: '50%',
        },
    },
    storiesSelect: {
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            width: 375,
        },
    },
}));

const UseStories: FC = () => {
    const classes = useStyles();
    const intl = useIntl();
    const [selectedStory, setSelectedStory] = useState<any>();

    const url = `${contentFragmentUrl}?type=${AEMMODELS.advisorStory}`;
    const { isLoading, error, data } = useQuery<AdvisorStoryResponse>(
        `lf-advisor-stories`,
        async () => {
            try {
                const res = await fetch(url);
                if (!res.ok) {
                    throw new Error(`there was an error when calling ${url}`);
                }
                return res.json();
            } catch (e) {
                console.error(e);
            }
        },
    );

    if (isLoading) {
        return <Loader />;
    }
    if (error || !data || !data?.articles[0].properties) {
        return null;
    }

    const stories = data.articles.map((story: any) => {
        return story.properties as AdvisorStory;
    });

    const options = stories
        .sort((a: AdvisorStory, b: AdvisorStory) => a.storyOrder - b.storyOrder)
        .map((story: AdvisorStory) => {
            return {
                text: story.dropdownText,
                value: story.storyOrder,
            };
        });

    const story =
        stories.find(
            (story: AdvisorStory) => story.storyOrder === selectedStory,
        ) || stories[0];

    return (
        <section className={classes.storiesWrapper}>
            <PageWrapper>
                <Typography
                    variant="h3"
                    className={classes.storiesSectionHeader}
                >
                    <FormattedMessage id="advisor.learnResource.useStories" />
                </Typography>
                <div className={classes.dropdownWrapper}>
                    <Select
                        analyticId="dropdownValue-"
                        analyticLabel="trackLink:selectionButtonClick"
                        analyticPlacement="Use stories:center-center"
                        data-qa="stories-select"
                        label={intl.formatMessage({
                            id: 'advisor.learnResource.interestedIn',
                        })}
                        className={classes.storiesSelect}
                        name="stories"
                        id="stories"
                        items={options}
                        value={selectedStory || options[0].value}
                        onChange={(
                            event: React.ChangeEvent<{
                                name?: string | undefined;
                                value: unknown;
                            }>,
                        ) => {
                            const value = event.target.value;
                            setSelectedStory(value as number);
                        }}
                    />
                </div>
                <div className={classes.storiesContentWrapper}>
                    <div className={classes.screenshotWrapper}>
                        <img
                            className={classes.screenshot}
                            src={story.screenshot}
                            alt={story.screenshotAlt}
                        />
                    </div>
                    <div className={classes.contentSectionWrapper}>
                        <ContentSection story={story} />
                    </div>
                </div>
            </PageWrapper>
        </section>
    );
};

export default UseStories;
