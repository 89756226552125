import { FC } from 'react';

/* material */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        lineHeight: '120%',
        padding: '16px 0',

        '& b': {
            color: theme.colors.purpleDark,
            display: 'block',
            fontWeight: 600,
        },
    },
}));

interface OptionProps {
    officialName: string;
    partyTaxId: number;
}

const Option: FC<OptionProps> = ({ officialName, partyTaxId }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <b>{officialName}</b>
            {partyTaxId && <div>EIN # {partyTaxId}</div>}
        </div>
    );
};

export default Option;
