/* nodes */
export const AEM_NODE_ADVISOR_ADVISOR_INFO = 'home/create/advisor-info';
export const AEM_NODE_ADVISOR_ADVISOR_INFO_START =
    'home/create/advisor-info-start';
export const AEM_NODE_ADVISOR_CONTACT_US = 'home/contact-us';
export const AEM_NODE_ADVISOR_FAQS = 'home/faqs';
export const AEM_NODE_ADVISOR_HOME = 'home/home';
export const AEM_NODE_ADVISOR_LEARN = 'home/learn';
export const AEM_NODE_ADVISOR_MANAGE = 'home/manage';
export const AEM_NODE_ADVISOR_PLAN_SPONSOR_INFO =
    'home/create/plan-sponsor-info';
export const AEM_NODE_ADVISOR_PLAN_SPONSOR_INFO_START =
    'home/create/plan-sponsor-info-create-website-start';
export const AEM_NODE_ADVISOR_REVIEW = 'home/create/review';
export const AEM_NODE_ADVISOR_SUCCESS = 'home/create/success';
export const AEM_NODE_LOGIN_START = 'home/login/start';
export const AEM_NODE_LOGIN_SUCCESS = 'home/login/success';
export const AEM_NODE_LOGIN_FAILURE = 'home/login/failure';
// export const AEM_NODE_SUBSCRIBE = 'home/subscribe';
export const AEM_NODE_SUBSCRIBE_START = 'home/subscribe/start';
export const AEM_NODE_SUBSCRIBE_COMPLETE = 'home/subscribe/complete';
export const AEM_NODE_UNSUBSCRIBE = 'home/unsubscribe';
export const AEM_NODE_UNSUBSCRIBE_START = 'home/unsubscribe/start';
export const AEM_NODE_UNSUBSCRIBE_COMPLETE = 'home/unsubscribe/complete';
export const AEM_NODE_UNSUBSCRIBE_FAILURE = 'home/unsubscribe/failure';

/* self service steps */
export const SELF_SERVICE_STEP = 'step';
export const SELF_SERVICE_START = 'start';
export const SELF_SERVICE_COMPLETE = 'complete';
export const SELF_SERVICE_ERROR = 'error';

/* self service activity name */
export const TOOL_ADVISOR_CREATE_WEBSITE = 'RPI Advisor Portal Create Website';
export const TOOL_ADVISOR_CREATE_WEBSITE_NEW =
    'RPI Advisor Portal Create Website - New';
export const TOOL_ADVISOR_CREATE_WEBSITE_MAKE_ANOTHER =
    'RPI Advisor Portal Create Website - Make Another';
export const TOOL_SUBSCRIBE = 'RPI Subscribe';
export const TOOL_UNSUBSCRIBE = 'RPI Unsubscribe';

export const RPI_ADVISOR_PORTAL = 'RPIAdvisorPortal';
