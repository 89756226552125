import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

/* material */
import { Button } from '@material-ui/core';

/* services */
import { createWebsite } from 'store/services/advisorServices';

/* component */
import { Loader } from 'components';

/* actions */
import {
    setActiveFlowComplete,
    setWebsiteCreationError,
    setWebsiteCreationSuccess,
} from 'store/actionCreators/createWebsiteActionCreator';

/* state */
import { AppState } from 'store/store/configureStore';

/* types */
import { CustomClaims } from '../Create/AdvisorInfo';

/* utils */
import { getLocalStorage } from 'utils/localStorage';

interface CreateCtaProps {
    dataAnalyticsId?: string;
    dataAnalyticsLabel?: string;
    dataAnalyticsPlacement?: string;
    messageId: string;
    onClick?: () => void;
}

const CreateCta: FC<CreateCtaProps> = ({
    dataAnalyticsId,
    dataAnalyticsLabel,
    dataAnalyticsPlacement,
    messageId,
    onClick,
}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { authState } = useOktaAuth();
    const {
        customUrl,
        displayName,
        industryNaicsCode2,
        planSponsorId,
    } = useSelector((state: AppState) => state.createWebsite, shallowEqual);

    if (!authState) return null;

    const {
        companyWebsite,
        designation,
        jobTitle,
        phoneNumber,
    } = getLocalStorage('advisor');
    const { uuid } = authState.accessToken?.claims as CustomClaims;

    const handleClick = async () => {
        setIsLoading(true);
        try {
            const data = await createWebsite({
                ...(designation && {
                    financialAdvisorDesignation: designation,
                }),
                ...(companyWebsite && {
                    financialAdvisorFirmWebUrl: companyWebsite,
                }),
                financialAdvisorIdentityUUID: uuid,
                financialAdvisorPhoneNumber: phoneNumber,
                ...(jobTitle && { financialAdvisorTitle: jobTitle }),
                ...(industryNaicsCode2 && { industryNaicsCode2 }),
                planSponsorDisplayName: displayName,
                planSponsorId,
                token: authState?.accessToken?.accessToken as string,
                urlSlug: customUrl,
            });

            dispatch(setWebsiteCreationSuccess(data));
        } catch (error) {
            console.error(error);
            dispatch(setWebsiteCreationError());
        } finally {
            setIsLoading(false);
            onClick && onClick();
            dispatch(setActiveFlowComplete());
        }
    };

    return (
        <>
            {isLoading && <Loader />}
            <Button
                data-analytics-id={dataAnalyticsId}
                data-analytics-label={dataAnalyticsLabel}
                data-analytics-placement={dataAnalyticsPlacement}
                disabled={isLoading}
                variant="contained"
                onClick={handleClick}
            >
                <FormattedMessage id={messageId} />
            </Button>
        </>
    );
};

export default CreateCta;
