import { FC } from 'react';

/* material */
import { makeStyles, Typography } from '@material-ui/core';

/* components */
import { PageWrapper } from 'components';

const useStyles = makeStyles((theme) => ({
    titleWrapper: {
        backgroundColor: theme.colors.navy,
        padding: '20px 0',

        [theme.breakpoints.up('sm')]: {
            padding: '40px 0',
        },
    },
    title: {
        color: theme.colors.white,
        fontSize: 30,

        [theme.breakpoints.up('sm')]: {
            fontSize: 72,
        },
    },
}));

interface PageBannerProps {
    title: string;
}

const PageBanner: FC<PageBannerProps> = ({ title }) => {
    const classes = useStyles();

    return (
        <section className={classes.titleWrapper}>
            <PageWrapper>
                <Typography variant="h1" className={classes.title}>
                    {title}
                </Typography>
            </PageWrapper>
        </section>
    );
};
export default PageBanner;
