import { FC, ReactElement } from 'react';

/* material */
import { makeStyles } from '@material-ui/core';

interface BlockStepStylesProps {
    active: boolean;
    isArrowVisible: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 180,
        position: 'relative',
        width: '100%',
        paddingRight: (props: BlockStepStylesProps) =>
            props.isArrowVisible ? 12 : 0,

        '&::after': {
            borderLeft: (props: BlockStepStylesProps) =>
                '12px solid' +
                (props.isArrowVisible
                    ? props.active
                        ? theme.colors.purpleDark
                        : theme.colors.navyTint1
                    : 'trasparent'),

            borderTop: '8px solid transparent',
            borderBottom: '8px solid transparent',
            content: '""',
            display: 'block',
            position: 'absolute',
            height: 0,
            right: -6,
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: 0,
        },
    },
    number: {
        alignItems: 'center',
        backgroundColor: (props: BlockStepStylesProps) =>
            props.active ? theme.colors.purpleDark : theme.colors.navyTint1,
        borderRadius: 17.5,
        color: theme.colors.white,
        display: 'flex',
        justifyContent: 'center',
        height: 35,
        width: 35,
    },
    icon: {
        alignItems: 'center',
        border: (props: BlockStepStylesProps) =>
            '6px solid' +
            (props.active ? theme.colors.purpleDark : theme.colors.navyTint1),
        borderRadius: 17.5,
        color: (props: BlockStepStylesProps) =>
            props.active ? theme.colors.purpleDark : theme.colors.navyTint1,
        display: 'flex',
        justifyContent: 'center',
        height: 35,
        width: 35,

        '& > svg': {
            fontSize: 32,
        },
    },
    label: {
        color: (props: BlockStepStylesProps) =>
            props.active ? theme.colors.purpleDark : theme.colors.navyTint1,
        fontSize: 18,
        fontWeight: 600,
    },
}));

interface BlockStepProps {
    icon?: ReactElement | null;
    isArrowVisible?: boolean;
    number: number;
    label: string;
    step: number;
}

const BlockStep: FC<BlockStepProps> = ({
    icon = null,
    isArrowVisible = true,
    label,
    number,
    step,
}) => {
    const active = number === step;
    const classes = useStyles({ active, isArrowVisible });

    return (
        <div className={classes.root}>
            {icon ? (
                <div className={classes.icon}>{icon}</div>
            ) : (
                <div className={classes.number}>{number}</div>
            )}
            <div className={classes.label}>{label}</div>
        </div>
    );
};

export default BlockStep;
