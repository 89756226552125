import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

/* material */
import { List, makeStyles, Typography } from '@material-ui/core';

/* component */
import { PageWrapper, NavPlaceHolder } from 'components';
import MenuLink, { Link } from '../MenuLink';
import LoginButton from './LoginButton';

/* menu config */
import { navLinks } from './config';

/* constants */
import { MENU_HEIGHT_LARGE } from 'components/NavigationBar/NavPlaceHolder';

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 auto',
        padding: '0 0',
        maxWidth: 850,
    },
    link: {
        borderBottom: `solid 4px transparent`,
        textDecoration: 'none',
        width: 'auto',
        '&:active': {
            borderColor: theme.colors.purple,
        },
        '& > div > span': {
            color: theme.colors.black,
            fontSize: 20,
            fontWeight: 'normal',
            padding: 0,

            [theme.breakpoints.up('md')]: {
                fontSize: 22,
            },
        },
    },
    active: {
        borderColor: theme.colors.purple,
    },
    title: {
        color: theme.colors.purpleAxis,
        marginTop: 15,
    },
    navBar: {
        backgroundColor: theme.colors.white,
        height: MENU_HEIGHT_LARGE,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 999,
    },
}));

const DesktopMenu: FC = () => {
    const classes = useStyles();
    const [activePath, setActivePath] = useState<string>('');

    const handleTabClick = (link: Link) => {
        setActivePath(link.path);
    };

    return (
        <>
            <NavPlaceHolder size="large" />
            <div className={classes.navBar}>
                <PageWrapper>
                    <Typography
                        data-qa="navigation-title"
                        className={classes.title}
                        variant="h5"
                        component="div"
                    >
                        <FormattedMessage id="retirementPlanInsights" />
                    </Typography>
                    <List
                        data-qa="desktop-navigation-list"
                        aria-label="main navigation"
                        className={classes.root}
                        component="nav"
                    >
                        {navLinks.map((link, idx) => {
                            return (
                                <MenuLink
                                    className={classes.link}
                                    activeClassName={classes.active}
                                    link={link}
                                    key={idx}
                                    analyticId={`RPIFAPortal: ${link.title}`}
                                    analyticLabel="trackLink:tab"
                                    analyticPlacement="Navigation:top-center"
                                    handleClick={handleTabClick}
                                    activePath={activePath}
                                />
                            );
                        })}
                        <LoginButton redirect={activePath} />
                    </List>
                </PageWrapper>
            </div>
        </>
    );
};

export default DesktopMenu;
