import {
    ADVISOR_CREATE_ADVISOR_INFO_PATH,
    ADVISOR_CREATE_PLAN_SPONSOR_PATH,
    ADVISOR_CREATE_REVIEW_PATH,
} from 'routes/AdvisorCreateRoutes';

export type BackLink = {
    label: string;
    url: string;
};

export type Step = {
    label: string;
    backLink?: BackLink;
} & { [key: string]: string | BackLink };

export const steps: Step[] = [
    { label: 'Advisor info' },
    {
        label: 'Plan sponsor info',
        backLink: {
            label: 'Back: Advisor information',
            url: ADVISOR_CREATE_ADVISOR_INFO_PATH,
        },
    },
    {
        label: 'Review info',
        backLink: {
            label: 'Back: Plan sponsor information',
            url: ADVISOR_CREATE_PLAN_SPONSOR_PATH,
        },
    },
    {
        label: 'Success!',
        backLink: {
            label: 'Back: Review information',
            url: ADVISOR_CREATE_REVIEW_PATH,
        },
    },
];
