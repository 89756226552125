import { v4 as uuidv4 } from 'uuid';

/* utils */
import { PlanSponsorConfig } from 'store/reducers/planSponsorReducer';
import { getHeaders } from 'store/services/utils';
import { PaginationProperties } from 'interfaces/pagination';
import { objectToUrlSearchParams } from 'utils/url';

export type GenerateUrlSlugResponse = {
    urlSlug: string;
};

export async function generateUrlSlug(
    displayName: string,
    officialName: string,
) {
    const url = `/lf/data/api/v1/sites/url-slug/generate?displayName=${encodeURIComponent(
        displayName,
    )}&officialName=${encodeURIComponent(officialName)}`;
    const res = await fetch(url, { method: 'GET' });

    if (!res.ok) throw Error(`Unable to generate plan sponsor slug`);

    return (await res.json()) as GenerateUrlSlugResponse;
}

export type ValidateUrlSlugIsUniqueResponse = {
    unique: boolean;
};

export async function validateUrlSlugIsUnique(urlSlug: string) {
    const url = `/lf/data/api/v1/sites/url-slug/unique?urlSlug=${urlSlug}`;
    const res = await fetch(url, { method: 'GET' });

    if (!res.ok) throw Error(`Unable to validate plan sponsor slug`);

    return (await res.json()) as ValidateUrlSlugIsUniqueResponse;
}

export type CreateWebsiteResponse = PlanSponsorConfig & {
    confirmationEmailSent: boolean;
};

interface CreateWebsitePayload {
    financialAdvisorDesignation?: string;
    financialAdvisorFirmWebUrl?: string;
    financialAdvisorIdentityUUID: string;
    financialAdvisorPhoneNumber: string;
    financialAdvisorTitle?: string;
    industryNaicsCode2?: string;
    planSponsorDisplayName: string;
    planSponsorId: number;
    token: string;
    urlSlug: string;
}

export async function createWebsite(payload: CreateWebsitePayload) {
    const url = `/lf/data/api/v1/sites/create`;
    const { token, ...others } = payload;

    const res = await fetch(url, {
        method: 'POST',
        headers: getHeaders({
            Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify({ ...others, id: uuidv4() }),
    });

    if (!res.ok) throw Error(`Unable to create website`);

    return (await res.json()) as CreateWebsiteResponse;
}

export interface FinancialAdvisorPutResponse {
    id: number;
    identityUUID: string;
    partyId: string;
    email: string;
    firstName: string;
    lastName: string;
    firmName: string;
    firmWebUrl?: string;
    title?: string;
    fullName: string;
    designation?: string;
    phoneNumber?: string;
}

export async function refreshFinancialAdvisorDataFromOkta(
    identityUUID: string,
    token: string,
) {
    const url = `/lf/data/api/v1/financial-advisors/identity-uuid/${identityUUID}/refresh`;
    const res = await fetch(url, {
        method: 'PUT',
        headers: getHeaders({
            Authorization: `Bearer ${token}`,
        }),
    });

    if (!res.ok) throw Error(`Unable to update FA`);
    return (await res.json()) as FinancialAdvisorPutResponse;
}

export type CreateWebsite = {
    siteId: number;
    siteUrlSlug: string;
    siteBrandType: string;
    siteDisplayPlanSponsorNameInPreparedForByStatement: boolean;
    siteDisplaySiteOwnerNameInPreparedForByStatement: boolean;
    siteDisplaySiteOwnerFirmNameInPreparedForByStatement: boolean;
    siteFooterType: string;
    siteCreatedDate: string;
    siteLastModifiedDate: string;
    planSponsorId: number;
    planSponsorPartyId: number;
    planSponsorPartyTaxId: number;
    planSponsorType: string;
    planSponsorOfficialName: string;
    planSponsorDisplayName: string;
};

export type FinancialAdvisorWebsitesResponse = {
    content: CreateWebsite[];
} & PaginationProperties;

export async function fetchFinancialAdvisorWebsites(
    uuid: string,
    planSponsorSearchText: string,
    token: string,
    page: number,
    size = 10,
    sort = 'planSponsorOfficialName,ASC',
) {
    const params = {
        ...(planSponsorSearchText && {
            planSponsorNameContains: planSponsorSearchText,
        }),
        page,
        size,
        sort,
    };
    const query = objectToUrlSearchParams(params);
    const url = `/lf/data/api/v1/financial-advisors/identity-uuid/${uuid}/sites?${query}`;
    const res = await fetch(url, {
        headers: getHeaders({
            Authorization: `Bearer ${token}`,
        }),
    });

    if (!res.ok) throw Error(`Unable to fetch financial advisor websites`);
    return (await res.json()) as FinancialAdvisorWebsitesResponse;
}
