import { FC, useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useGetPath } from '@capgroup-cxt/aem-react-utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { UserClaims } from '@okta/okta-auth-js';

/* constants */
import { TITLE_CREATE_WEBSITE_ADVISOR_INFO } from 'constants/pageTitles';
import { ADVISOR_CREATE_PLAN_SPONSOR_PATH } from 'routes/AdvisorCreateRoutes';
import {
    TOOL_ADVISOR_CREATE_WEBSITE,
    SELF_SERVICE_START,
    SELF_SERVICE_STEP,
    AEM_NODE_ADVISOR_ADVISOR_INFO_START,
} from 'analytics/constants';

/* material */
import { Typography, makeStyles, Button } from '@material-ui/core';

/* components */
import { CreateContentWrapper, InfoField, Warning } from '../Components';
import { TextField } from 'components';

/* utils */
import {
    phoneFormatRegex,
    phoneRegex,
    httpRegex,
    lettersNumbersRegex,
} from 'utils/regex';
import { getLocalStorage, saveToLocalStorage } from 'utils/localStorage';
import { getStepLabel } from './PlanSponsorInfo/PlanSponsorInfo';

/* actions */
import {
    resetCreateAnotherFlow,
    resetCreateWebsiteInfo,
    resetPlanSponsorInfo,
    setActiveFlowStart,
    setActiveStep,
} from 'store/actionCreators/createWebsiteActionCreator';

/* state */
import { AppState } from 'store/store/configureStore';

/* analytics */
// import { analyticsAdvisorMultiStepEvent } from 'analytics/analyticsMultiStepEvent';
// import { attachFormAbandonementAnalytics } from 'analytics/analyticsFormEvent';
import { pageLoadStartedEvent, pageLoadCompletedEvent } from 'analytics';
import {
    formAnalytics,
    multiStepEvent,
    multiStepErrorMessageEvent,
} from 'analytics/index';

const PHONE_NUMBER = 'phoneNumber';
const WEBSITE = 'website';
const JOB_TITLE = 'jobTitle';
const DESIGNATION = 'designation';
const ACTIVE_STEP = 1;

export const getFormErrors = (
    errors: { [key: string]: boolean },
    intl: IntlShape,
    errorKey: string,
    errorMessageMap: { [key: string]: string },
) => {
    const formErrors = Object.keys(errors).reduce((errs: string[], key) => {
        const messageId = errorMessageMap[key];
        const isSameErrorKey = key === errorKey;
        const isError = errors[key];
        if (messageId && isError && !isSameErrorKey) {
            const errMsg = intl.formatMessage({ id: messageId });
            errs.push(errMsg);
        }
        return errs;
    }, []);

    formErrors.push(intl.formatMessage({ id: errorMessageMap[errorKey] }));
    return formErrors.join('|');
};

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        backgroundColor: theme.colors.navyTint2,
        borderBottom: `solid 1px ${theme.colors.purpleLight}`,
        borderTop: `solid 1px ${theme.colors.purpleLight}`,
        display: 'flex',
        flexDirection: 'column',
        margin: '0 0 30px',
        padding: '20px 20px 10px',

        [theme.breakpoints.up('sm')]: {
            border: `solid 1px ${theme.colors.purpleLight}`,
            borderRadius: 10,
            padding: '30px 30px 0',
            margin: '0 auto 30px',
            maxWidth: `${theme.breakpoints.values['md']}px`,
        },
    },
    imageLayoutWrapper: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    imageWrapper: {
        height: 220,
        marginBottom: 30,
        maxWidth: 567,
        overflow: 'hidden',
        position: 'relative',
        width: '100%',

        '& > img': {
            position: 'absolute',
        },

        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
        },
    },
    textField: {
        marginBottom: 36,
        maxWidth: 375,
    },
    ctaWrapper: {
        marginBottom: 30,

        [theme.breakpoints.up('sm')]: {
            paddingLeft: 30,
        },

        '& > button': {
            margin: '10px 0 30px',
        },
    },
    header: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 30,
        },

        '& > p': {
            margin: '20px 0 30px',
        },
    },
}));

export type CustomClaims = UserClaims<{
    firstName: string;
    lastName: string;
    firmName: string;
    email: string;
    uuid: string;
}>;

const errorMessageTranslationIdMap: { [key: string]: string } = {
    [JOB_TITLE]: 'advisor.create.advisorInfo.invalidChars',
    [DESIGNATION]: 'advisor.create.advisorInfo.invalidChars',
    [PHONE_NUMBER]: 'advisor.create.advisorInfo.invalidPhone',
    [WEBSITE]: 'advisor.create.advisorInfo.invalidUrl',
};

type Inputs = {
    [JOB_TITLE]: string;
    [DESIGNATION]: string;
    [PHONE_NUMBER]: string;
    [WEBSITE]: string;
} & { [key: string]: string };

type Errors = {
    [JOB_TITLE]: boolean;
    [DESIGNATION]: boolean;
    [PHONE_NUMBER]: boolean;
    [WEBSITE]: boolean;
} & { [key: string]: boolean };

const AdvisorInfo: FC = () => {
    const { authState } = useOktaAuth();
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const isAnalyticsFired = useRef(false);
    const { isCreateAnother, isFlowComplete, isFlowActive } = useSelector(
        (state: AppState) => state.createWebsite,
        shallowEqual,
    );
    const {
        companyWebsite,
        designation,
        jobTitle,
        phoneNumber,
    } = getLocalStorage('advisor');
    const [inputs, setInputs] = useState<Inputs>({
        [JOB_TITLE]: jobTitle,
        [DESIGNATION]: designation,
        [PHONE_NUMBER]: phoneNumber,
        [WEBSITE]: companyWebsite,
    });
    const [errors, setErrors] = useState<Errors>({
        [JOB_TITLE]: false,
        [DESIGNATION]: false,
        [PHONE_NUMBER]: false,
        [WEBSITE]: false,
    });
    const { email, firmName, firstName, lastName } = authState?.accessToken
        ?.claims as CustomClaims;
    const firmNameImage = useGetPath(
        '/content/dam/leapfrog/images/advisor/create/fa-firm-name-example.png',
    );
    const { label, step } = getStepLabel(isCreateAnother, ACTIVE_STEP);

    useEffect(() => {
        // attachFormAbandonementAnalytics(
        //     `${TOOL_ADVISOR_CREATE_WEBSITE}-Advisor Info`,
        //     Array.from(document.querySelectorAll('[data-qa=drawer-menu-item]')),
        // );
        const ElementList = Array.from(
            document.querySelectorAll('input.MuiInput-input'),
        ) as HTMLElement[];
        formAnalytics(
            ElementList,
            'Multistep Form-Step 2: Multistep Form Step - Advisor Info',
        );
        dispatch(setActiveStep(ACTIVE_STEP));
    }, [dispatch]);

    useEffect(() => {
        if (isFlowActive) return;
        // initial page view call, flow has not started
        pageLoadStartedEvent(
            'create > advisor-info',
            'create',
            'RPIAdvisorPortal',
        );
        pageLoadCompletedEvent();
        dispatch(resetCreateWebsiteInfo());
    }, [dispatch, isFlowActive]);

    useEffect(() => {
        // revisiting advisor as a step in the flow
        if (isFlowActive && !isFlowComplete && !isAnalyticsFired.current) {
            multiStepEvent({
                analyticsAppName: AEM_NODE_ADVISOR_ADVISOR_INFO_START,
                eventName: SELF_SERVICE_STEP,
                name: `${step}: ${TOOL_ADVISOR_CREATE_WEBSITE} - Advisor Info`,
                sessionSettings: 'Session',
            });

            // analyticsAdvisorMultiStepEvent({
            //     label,
            //     action: SELF_SERVICE_STEP,
            //     AEMNodePath: AEM_NODE_ADVISOR_ADVISOR_INFO_START,
            //     value: `${step}: ${TOOL_ADVISOR_CREATE_WEBSITE} - Advisor Info`,
            //     result: '',
            // });
            isAnalyticsFired.current = true;
        }
    }, [isFlowActive, label, step, isFlowComplete]);

    useEffect(() => {
        // reset plan sponsor info if user completes flow and hits browser back button
        if (isFlowComplete) {
            dispatch(resetPlanSponsorInfo());
            dispatch(resetCreateAnotherFlow());

            multiStepEvent({
                analyticsAppName: AEM_NODE_ADVISOR_ADVISOR_INFO_START,
                eventName: SELF_SERVICE_STEP,
                name: `${step}: ${TOOL_ADVISOR_CREATE_WEBSITE} - Advisor Info`,
                sessionSettings: 'Session',
            });

            // consider it a new flow and a start step
            // analyticsAdvisorMultiStepEvent({
            //     label,
            //     action: SELF_SERVICE_START,
            //     AEMNodePath: AEM_NODE_ADVISOR_ADVISOR_INFO_START,
            //     value: `${step}: ${TOOL_ADVISOR_CREATE_WEBSITE} - Advisor Info`,
            //     result: '',
            // });
            isAnalyticsFired.current = true;
        }
    }, [dispatch, isFlowComplete, isFlowActive, label, step]);

    if (!authState) return null;

    const validateField = (key: string, value: string): boolean => {
        let isValid = true;
        switch (key) {
            case PHONE_NUMBER:
                isValid = phoneRegex.test(value);
                break;
            case WEBSITE:
                isValid = value ? httpRegex.test(value) : true;
                break;
            default:
                isValid = value ? lettersNumbersRegex.test(value) : true;
        }

        return isValid;
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const key = target.name;
        let value = target.value;

        setInputs({
            ...inputs,
            [key]: value,
        });
    };

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const target = event.currentTarget;
        const key = target.name;
        const value = target.value;

        let isValid = validateField(key, value);

        if (!isValid) {
            const formErrors = getFormErrors(
                errors,
                intl,
                key,
                errorMessageTranslationIdMap,
            );
            multiStepErrorMessageEvent(
                AEM_NODE_ADVISOR_ADVISOR_INFO_START,
                `${step}: ${TOOL_ADVISOR_CREATE_WEBSITE} - Advisor Info`,
                formErrors.split('|'),
            );
            // analyticsAdvisorMultiStepEvent(
            //     {
            //         label,
            //         action: SELF_SERVICE_ERROR,
            //         AEMNodePath: AEM_NODE_ADVISOR_ADVISOR_INFO_START,
            //         value: `${step}: ${TOOL_ADVISOR_CREATE_WEBSITE} - Advisor Info`,
            //         result: '',
            //     },
            //     {
            //         name: label,
            //         errorList: formErrors,
            //     },
            // );
        }

        setErrors({
            ...errors,
            [key]: !isValid,
        });
    };

    const handleInputFocus = () => {
        if (isCreateAnother || isAnalyticsFired.current) return;
        multiStepEvent({
            analyticsAppName: AEM_NODE_ADVISOR_ADVISOR_INFO_START,
            eventName: SELF_SERVICE_START,
            name: `${step}: ${TOOL_ADVISOR_CREATE_WEBSITE} - Advisor Info`,
            sessionSettings: 'Session',
        });

        // analyticsAdvisorMultiStepEvent({
        //     label,
        //     action: SELF_SERVICE_START,
        //     AEMNodePath: AEM_NODE_ADVISOR_ADVISOR_INFO_START,
        //     value: `${step}: ${TOOL_ADVISOR_CREATE_WEBSITE} - Advisor Info`,
        //     result: '',
        // });
        isAnalyticsFired.current = true;
    };

    const handleNextButtonClick = () => {
        const formErrors = { ...errors };
        Object.keys(errors).forEach((key: string) => {
            const value = inputs[key];
            let isValid = validateField(key, value);
            formErrors[key] = !isValid;
        });

        setErrors(formErrors);

        const hasErrors = Object.keys(formErrors).some((key: string) => {
            return formErrors[key];
        });

        if (hasErrors) return;

        if (!isAnalyticsFired.current) {
            multiStepEvent({
                analyticsAppName: AEM_NODE_ADVISOR_ADVISOR_INFO_START,
                eventName: SELF_SERVICE_START,
                name: `${step}: ${TOOL_ADVISOR_CREATE_WEBSITE} - Advisor Info`,
                sessionSettings: 'Session',
            });
            // analyticsAdvisorMultiStepEvent({
            //     label,
            //     action: SELF_SERVICE_START,
            //     AEMNodePath: AEM_NODE_ADVISOR_ADVISOR_INFO_START,
            //     value: `${step}: ${TOOL_ADVISOR_CREATE_WEBSITE} - Advisor Info`,
            //     result: '',
            // });
            isAnalyticsFired.current = true;
        }

        saveToLocalStorage('advisor', {
            companyWebsite: inputs[WEBSITE],
            designation: inputs[DESIGNATION].trim(),
            jobTitle: inputs[JOB_TITLE].trim(),
            phoneNumber: inputs[PHONE_NUMBER].replace(/\D+/g, ''),
        });

        setTimeout(() => {
            history.push(ADVISOR_CREATE_PLAN_SPONSOR_PATH);
            dispatch(setActiveFlowStart());
        }, 600);
    };

    return (
        <div data-qa="advisor-info-form">
            <Helmet>
                <title>{TITLE_CREATE_WEBSITE_ADVISOR_INFO}</title>
            </Helmet>
            <CreateContentWrapper className={classes.header}>
                <Typography component="h2" variant="h3">
                    <FormattedMessage id="advisor.create.advisorInformation" />
                </Typography>
                <Typography component="p" variant="body1">
                    <FormattedMessage id="advisor.create.advisorInfo.firmInfo" />
                </Typography>
            </CreateContentWrapper>
            <CreateContentWrapper
                className={`${classes.formWrapper} formcontainer`}
            >
                <Warning messageId="advisor.create.advisorInfo.followingInfo" />
                <div className={classes.imageLayoutWrapper}>
                    <div>
                        <InfoField
                            messageId="advisor.create.firstName"
                            value={firstName}
                        />
                        <InfoField
                            messageId="advisor.create.lastName"
                            value={lastName}
                        />
                        <InfoField
                            messageId="advisor.create.firmName"
                            value={firmName}
                        />
                    </div>
                    <div className={classes.imageWrapper}>
                        <img
                            alt=""
                            src={firmNameImage}
                            height="220"
                            width="567"
                        />
                    </div>
                </div>
                <TextField
                    className={classes.textField}
                    error={errors[JOB_TITLE]}
                    helperText={intl.formatMessage({
                        id: errorMessageTranslationIdMap[JOB_TITLE],
                    })}
                    id={JOB_TITLE}
                    label={intl.formatMessage({
                        id: 'advisor.create.advisorTitle',
                    })}
                    name={JOB_TITLE}
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    placeholder={intl.formatMessage({
                        id: 'advisor.create.advisorInfo.jobTitle',
                    })}
                    value={inputs[JOB_TITLE]}
                />
                <TextField
                    className={classes.textField}
                    error={errors[DESIGNATION]}
                    helperText={intl.formatMessage({
                        id: errorMessageTranslationIdMap[DESIGNATION],
                    })}
                    id={DESIGNATION}
                    label={intl.formatMessage({
                        id: 'advisor.create.designation',
                    })}
                    name={DESIGNATION}
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    placeholder={intl.formatMessage({
                        id: 'advisor.create.advisorInfo.exampleCRPS',
                    })}
                    value={inputs[DESIGNATION]}
                />
                <TextField
                    className={classes.textField}
                    error={errors[PHONE_NUMBER]}
                    helperText={intl.formatMessage({
                        id: errorMessageTranslationIdMap[PHONE_NUMBER],
                    })}
                    id={PHONE_NUMBER}
                    label={intl.formatMessage({
                        id: 'advisor.create.advisorInfo.phoneNumber',
                    })}
                    name={PHONE_NUMBER}
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    placeholder={intl.formatMessage({
                        id: 'advisor.create.advisorInfo.000',
                    })}
                    value={phoneFormatRegex(inputs[PHONE_NUMBER])}
                />
                <TextField
                    className={classes.textField}
                    error={errors[WEBSITE]}
                    helperText={intl.formatMessage({
                        id: errorMessageTranslationIdMap[WEBSITE],
                    })}
                    id={WEBSITE}
                    label={intl.formatMessage({
                        id: 'advisor.create.advisorInfo.webUrl',
                    })}
                    name={WEBSITE}
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    placeholder={intl.formatMessage({
                        id: 'advisor.create.advisorInfo.webUrl',
                    })}
                    value={inputs[WEBSITE]}
                />
            </CreateContentWrapper>
            <CreateContentWrapper className={classes.ctaWrapper}>
                <InfoField messageId="advisor.create.email" value={email} />
                <Button
                    data-analytics-id={intl.formatMessage({
                        id: 'advisor.create.advisorInfo.next',
                    })}
                    data-analytics-label="trackLink:buttonClick"
                    data-analytics-placement="Next button:bottom-left"
                    onClick={handleNextButtonClick}
                    variant="contained"
                >
                    <FormattedMessage id="advisor.create.advisorInfo.next" />
                </Button>
                <Typography component="div" variant="body1">
                    <FormattedMessage id="advisor.create.advisorInfo.required" />
                </Typography>
            </CreateContentWrapper>
        </div>
    );
};

export default AdvisorInfo;
