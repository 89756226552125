import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

/* material */
import { makeStyles, Typography } from '@material-ui/core';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.colors.purpleDark,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 20,

        '& > div': {
            fontWeight: 600,
        },
    },
}));

interface WarningProps {
    messageId?: string;
    children?: React.ReactElement;
}

const Warning: FC<WarningProps> = ({ children, messageId }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <PriorityHighIcon />
            <Typography variant="body1" component="div">
                {children
                    ? children
                    : messageId && <FormattedMessage id={messageId} />}
            </Typography>
        </div>
    );
};

export default Warning;
