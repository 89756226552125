export const pageLoadStartedEvent = (
    pageName: string,
    siteSection: string,
    audience: string,
) => {
    setTimeout(() => {
        window.appEventData = window.appEventData || [];

        window.appEventData.push({
            event: 'Page Load Started',
            page: {
                baseURL: window.location.origin.toString(),
                channel: 'Capital Group - retirement-plan-insights',
                navigationPath: '',
                pageName,
                siteAudience: audience,
                siteCountry: 'us',
                siteLanguage: 'en',
                siteSection1: siteSection,
                siteSection2: '',
                siteSection3: '',
                siteSection4: '',
                webPageType: 'SPA',
            },
            user: {
                advisorId: '',
                dealerCode: '',
                institutionalID: '',
                investorId: '',
                investorPartyId: '',
                partyId: '',
                pcsSfmcId: '',
                personaSegment: '',
                planId: '',
                planParticipantId: '',
                tracSponsorId: '',
                userRole: '',
                visionId: '',
            },
        });
    }, 100);
};
