import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

/* constants */
import { ADVISOR_CREATE_ADVISOR_INFO_PATH } from 'routes/AdvisorCreateRoutes';

/* actions */
// import { resetCreateWebsiteInfo } from 'store/actionCreators/createWebsiteActionCreator';

const RedirectToStart = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(resetCreateWebsiteInfo());
        history.replace(ADVISOR_CREATE_ADVISOR_INFO_PATH);
    }, [dispatch, history]);

    return null;
};

export default RedirectToStart;
