import { FC } from 'react';
import { useHistory } from 'react-router';

/* material */
import { makeStyles } from '@material-ui/core';

/* component */
import { LinkButton } from 'components';

/* icon */
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

/* config */
import { steps } from 'containers/Advisor/Components/config';

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        borderTop: `solid 1px #979797`,
        color: theme.colors.blue,
        display: 'flex',
        marginTop: 40,
        padding: '20px 0px 60px',

        [theme.breakpoints.up('sm')]: {
            padding: '26px 0px 100px',
        },

        '& > button': {
            fontSize: 16,
            margin: 0,
        },
    },
}));

interface FooterNavProps {
    activeStep?: number;
    label?: string;
    url?: string;
    onClick?: () => void;
}

const FooterNav: FC<FooterNavProps> = ({ activeStep, onClick, label, url }) => {
    const classes = useStyles();
    const history = useHistory();

    let text = label;
    let backLink = url;

    if (activeStep) {
        const stepConfig = steps[activeStep - 1];
        text = stepConfig.backLink?.label;
        backLink = stepConfig.backLink?.url;
    }

    if (!onClick && !backLink) return null;

    const handleClick = () => {
        backLink && history.replace(backLink);
        onClick && onClick();
    };

    return (
        <div className={classes.root}>
            <NavigateBeforeIcon />
            <LinkButton component="button" onClick={handleClick}>
                {text}
            </LinkButton>
        </div>
    );
};

export default FooterNav;
