import { FC, useState } from 'react';

/* material */
import { makeStyles, Button, Typography } from '@material-ui/core';

/* components */
import { LinkButton, PageModal, Iframe } from 'components';
import LinkList from './LinkList';
import List from './List';

/* utils */
import { parseJson } from 'utils/parseJson';

/* constants */
import { PLAN_SPONSOR_COMPANY } from 'constants/plansponsors';

/* types */
import { ContentLinkItem } from './LinkList';
import { ContentFragment } from 'interfaces/contentFragment';

export type AdvisoryStoryFragment = ContentFragment & {
    properties: AdvisorStory;
};

export type AdvisorStory = {
    dropdownText: string;
    screenshot: string;
    screenshotAlt: string;
    storyContentConfig: string;
    storyOrder: number;
};

type AdvisorStorySection = {
    action?: {
        type: 'modal' | 'email';
        emailBody?: string;
        ctaText: string;
        url?: string;
        modalTitle?: string;
    };
    content: {
        type: 'ordered-list' | 'unordered-list' | 'text' | 'link-list';
        items?: string[] | ContentLinkItem[];
        copy?: string;
    };
    header: string;
};

const useStyles = makeStyles((theme) => ({
    sectionWrapper: {
        marginTop: 30,
        '& > p': {
            margin: '12px 0',
        },
    },
    sectionHeader: {
        color: theme.colors.purpleDark,
    },
    modalLink: {
        marginBottom: 0,
    },
    emailBtnWrapper: {
        display: 'flex',
        justifyContent: 'center',
        margin: `20px 0 10px`,

        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-start',
            marginBottom: 40,
        },
    },
    linkButtonWrapper: {
        marginTop: 10,

        '& > button': {
            [theme.breakpoints.up('sm')]: {
                marginBottom: 0,
            },
        },
    },
    text: {
        fontSize: 16,
        margin: 0,
        padding: 0,

        [theme.breakpoints.up('sm')]: {
            fontSize: 22,
        },
    },
}));

interface ContentSectionProps {
    story: AdvisorStory;
}

const ContentSection: FC<ContentSectionProps> = ({ story }) => {
    const classes = useStyles();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const contentSections = (parseJson(story.storyContentConfig) ||
        []) as AdvisorStorySection[];

    const handleModalClick = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleMailClick = (emailBody: string) => {
        window.open(`mailto:?body=${emailBody}`);
    };

    return (
        <>
            {contentSections.map(
                (section: AdvisorStorySection, idx: number) => {
                    const {
                        header,
                        content: { type: contentType, items, copy },
                        action,
                    } = section;
                    const actionType = action?.type;
                    const actionDisplayText = action?.ctaText;
                    const actionUrl = action?.url;
                    const modalTitle = action?.modalTitle;
                    const emailBody = action?.emailBody;

                    return (
                        <div className={classes.sectionWrapper} key={idx}>
                            <Typography
                                variant="h4"
                                className={classes.sectionHeader}
                            >
                                {header}
                            </Typography>
                            {contentType === 'text' && (
                                <Typography className={classes.text}>
                                    {copy}
                                </Typography>
                            )}
                            {(contentType === 'ordered-list' ||
                                contentType === 'unordered-list') && (
                                <List
                                    items={items as string[]}
                                    listType={
                                        contentType === 'ordered-list'
                                            ? 'ordered'
                                            : 'unordered'
                                    }
                                />
                            )}
                            {contentType === 'link-list' && (
                                <LinkList items={items as ContentLinkItem[]} />
                            )}
                            {actionType === 'email' && emailBody && (
                                <div className={classes.emailBtnWrapper}>
                                    <Button
                                        data-analytics-id="Preview email"
                                        data-analytics-label="trackLink:buttonClick"
                                        data-analytics-placement="Use stories: center-right"
                                        variant="contained"
                                        onClick={() => {
                                            handleMailClick(emailBody);
                                        }}
                                    >
                                        {actionDisplayText}
                                    </Button>
                                </div>
                            )}
                            {actionType === 'modal' && (
                                <>
                                    <div className={classes.linkButtonWrapper}>
                                        <LinkButton
                                            data-analytics-id="Explore a demo of story"
                                            data-analytics-label="trackLink:buttonClick"
                                            data-analytics-placement="Use stories: center-right"
                                            component="button"
                                            onClick={handleModalClick}
                                        >
                                            <Typography
                                                component="span"
                                                variant="body1"
                                            >
                                                {actionDisplayText}
                                            </Typography>
                                        </LinkButton>
                                    </div>
                                    <PageModal
                                        open={isModalOpen}
                                        title={modalTitle}
                                        onClose={handleModalClose}
                                    >
                                        <Iframe
                                            title="retirementplaninsights"
                                            src={`/${PLAN_SPONSOR_COMPANY}${actionUrl}`}
                                        />
                                    </PageModal>
                                </>
                            )}
                        </div>
                    );
                },
            )}
        </>
    );
};

export default ContentSection;
