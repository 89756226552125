import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

/* components */
import NavigationBar from 'components/NavigationBar';
import MenuLink, { Link } from '../MenuLink';
import LoginButton from './LoginButton';
import { makeStyles, Typography, List } from '@material-ui/core';

/* menu config */
import { navLinks } from './config';
import { ADVISOR_HOME_PATH } from 'routes/AdvisorRoutes';

const useStyles = makeStyles((theme) => ({
    list: {
        marginLeft: 40,
        padding: 0,
    },
    link: {
        borderBottom: theme.topMenu.border,
        display: 'block',
        textDecoration: 'none',
    },
    title: {
        color: theme.colors.purpleAxis,
    },
}));

const MobileMenu: FC = () => {
    const classes = useStyles();
    const [isNavMenuOpen, setIsNavMenuOpen] = useState<boolean>(false);
    const [activePath, setActivePath] = useState<string>('');

    const toggleDrawer = () => {
        setIsNavMenuOpen(!isNavMenuOpen);
    };

    const handleMenuLinkClick = (link: Link) => {
        setActivePath(link.path);
        toggleDrawer();
    };

    return (
        <NavigationBar
            handleToggleDrawer={toggleDrawer}
            homePath={ADVISOR_HOME_PATH}
            isAdvisorPortal
            isNavMenuOpen={isNavMenuOpen}
            title={
                <Typography
                    className={classes.title}
                    variant="h5"
                    component="div"
                >
                    <FormattedMessage id="retirementPlanInsights" />
                </Typography>
            }
        >
            <List component="div" className={classes.list}>
                {navLinks.slice(1).map((link, idx) => {
                    return (
                        <MenuLink
                            handleClick={handleMenuLinkClick}
                            link={link}
                            key={idx}
                            className={classes.link}
                            analyticId={`RPIFAPortal: ${link.title}`}
                            analyticLabel="trackLink:anchorClick"
                            analyticPlacement="Navigation:top-right"
                        />
                    );
                })}
                <LoginButton onClick={toggleDrawer} redirect={activePath} />
            </List>
        </NavigationBar>
    );
};

export default MobileMenu;
