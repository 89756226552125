import { FC, useRef, useState, useEffect } from 'react';
import { AppState } from 'store/store/configureStore';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import ReCAPTCHA from 'react-google-recaptcha';

/* material */
import {
    Button,
    IconButton,
    makeStyles,
    Modal,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Clear } from '@material-ui/icons';

/* components */
import { Alert, Loader, TextField } from 'components';

/* constants */
import { SITE_BRAND_CG, SITE_BRAND_RPI } from 'constants/plansponsors';

/* services */
import { subscribe } from 'store/services/planSponsorServices';

/* utils */
import { getGlobalConfig } from 'utils/config';
import { pxToRem } from 'theme/utils';
import { lettersRegex } from 'utils/regex/letters';
import { lettersSpecialRegex } from 'utils/regex/lettersSpecial';
import { lettersNumbersRegexWithCharacterRange } from 'utils/regex/lettersNumbers';
import { validCharsRegexWithCharacterRange } from 'utils/regex/validChars';
import { emailRegex } from 'utils/regex/email';
import { getFormErrors } from 'containers/Advisor/Create/AdvisorInfo';

/* analytics */
// import { analyticsAdvisorMultiStepEvent } from 'analytics/analyticsMultiStepEvent';
import {
    formAnalytics,
    multiStepEvent,
    multiStepErrorMessageEvent,
    pageLoadStartedEvent,
    pageLoadCompletedEvent,
} from 'analytics/index';
import {
    SELF_SERVICE_COMPLETE,
    SELF_SERVICE_START,
    TOOL_SUBSCRIBE,
} from 'analytics/constants';
// import { getPageNodeFromContextHub } from 'analytics/utils';

const useStyles = makeStyles((theme) => ({
    root: {
        border: theme.nextStepModal.border,
        borderRadius: '8px',
        boxShadow: theme.nextStepModal.boxShadow,
        backgroundColor: theme.colors.white,
        color: theme.colors.navy,
        display: 'flex',
        flexDirection: 'column',
        height: '85%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        padding: '20px',
        paddingTop: '60px',
        width: '80%',
        [theme.breakpoints.up('sm')]: {
            border: theme.nextStepModal.border,
            borderRadius: '8px',
            boxShadow: theme.nextStepModal.boxShadow,
            height: 'unset',
            maxWidth: theme.breakpoints.values.lg,
            overflow: 'hidden',
            padding: '41px',
        },
    },
    closeBtn: {
        padding: 0,
        marginBottom: '20px',
        position: 'absolute',
        top: 20,
        right: 21,
        zIndex: 1,

        [theme.breakpoints.up('sm')]: {
            top: 18,
            right: 25,
        },
    },
    closeIcon: {
        color: theme.nextStepModal.closeIcon.color,
        fontSize: pxToRem(22),

        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(40),
        },
    },
    column: {
        maxWidth: '400px',
        width: '50%',
    },
    contentWrapper: {
        height: '100%',
        overflowY: 'scroll',
    },
    error: {
        fontSize: '16px',
        lineHeight: '24px',
        marginBottom: '20px',
        padding: '25px 25px 30px 25px',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
            lineHeight: '26px',
            margin: '5px 40px 30px 0px',
            padding: '25px 25px 30px 25px',
        },
    },
    footerText: {
        fontSize: '14px',
        marginTop: '20px',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '5px',
            textAlign: 'left',
        },
    },
    headerRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    row: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    recaptchaWrapper: {
        display: 'flex',
        marginTop: '5px',
        transform: 'scale(80%)',
        transformOrigin: '0 0',
        width: '100px',
        [theme.breakpoints.up('md')]: {
            transform: 'scale(1)',
        },
    },
    submitButton: {
        marginRight: '20px',
        width: '200%',
        marginTop: '15px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '30px',
            marginLeft: '0px',
            marginRight: '0px',
            width: '180px',
        },
    },
    subtext: {
        fontSize: '16px',
        paddingBottom: '20px',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
            paddingBottom: '20px',
        },
    },
    subsubtext: {
        fontSize: '18px',
        paddingBottom: '31px',
    },
    subsubtextTitle: {
        color: theme.subscribeModal.color,
        fontSize: '14px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
        },
    },
    textField: {
        marginRight: '20px',
        width: '200%',
        '& > label': {
            fontSize: '16px',
            fontWeight: 500,
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: '0px',
            width: '345px',
        },
    },
    title: {
        fontSize: '22px',
        fontWeight: 600,
        paddingBottom: '10px',
        position: 'relative',

        [theme.breakpoints.up('sm')]: {
            fontSize: '30px',
            paddingBottom: '21px',
        },
    },
}));
const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const JOB_TITLE = 'jobTitle';
const EMAIL = 'email';
const COMPANY_NAME = 'companyName';
const { GOOGLE_RECAPTCHA_V2_KEY_SITE } = getGlobalConfig();

const errorMessageTranslationIdMap: { [key: string]: string } = {
    [FIRST_NAME]: 'subscribe.onlyLettersError',
    [LAST_NAME]: 'subscribe.specialCharactersOtherError',
    [JOB_TITLE]: 'subscribe.specialCharacterError',
    [EMAIL]: 'subscribe.emailError',
    [COMPANY_NAME]: 'subscribe.bracketError',
};

interface SubscribeModalProps {
    onCloseIcon: () => void;
    onClose: () => void;
    open: boolean;
}
const formState = {
    [FIRST_NAME]: '',
    [LAST_NAME]: '',
    [JOB_TITLE]: '',
    [EMAIL]: '',
    [COMPANY_NAME]: '',
};
const formStateError = {
    [FIRST_NAME]: false,
    [LAST_NAME]: false,
    [JOB_TITLE]: false,
    [EMAIL]: false,
    [COMPANY_NAME]: false,
};

const SubscribeModal: FC<SubscribeModalProps> = ({
    onCloseIcon,
    onClose,
    open = false,
}) => {
    const classes = useStyles();
    const intl = useIntl();
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const isFormTouched = useRef(false);
    const closeBtnRef = useRef<HTMLButtonElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRecaptchaValid, setIsRecaptchaValid] = useState<boolean>(false);
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(true);
    const [inputs, setInputs] = useState({ ...formState });
    const [errors, setErrors] = useState({ ...formStateError });
    const urlSlug = useSelector((state: AppState) => state.planSponsorSlug.id);
    // const aemPageNode = getPageNodeFromContextHub();
    const brandType = useSelector(
        (state: AppState) => state.planSponsor.config?.brandType,
    );

    useEffect(() => {
        if (!open) return;
        setTimeout(() => {
            // attachFormAbandonementAnalytics(TOOL_SUBSCRIBE, [
            //     closeBtnRef.current as Element,
            // ]);
            const ElementList = Array.from(
                document.querySelectorAll('input.MuiInput-input'),
            ) as HTMLElement[];
            formAnalytics(
                ElementList,
                'Multistep Form-Step 2: Multistep Form Step- Subscribe Modal',
            );
        }, 0);
    }, [open]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const name = event.target.name;

        setInputs({
            ...inputs,
            [name]: value,
        });
    };

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const name = event.target.name;

        let isValid = false;
        switch (name) {
            case FIRST_NAME:
                isValid = lettersRegex.test(value);
                break;
            case LAST_NAME:
                isValid = lettersSpecialRegex.test(value);
                break;
            case JOB_TITLE:
                isValid = lettersNumbersRegexWithCharacterRange(2, 200).test(
                    value,
                );
                break;
            case EMAIL:
                isValid = emailRegex.test(value);
                break;
            case COMPANY_NAME:
                isValid =
                    brandType === SITE_BRAND_CG
                        ? validCharsRegexWithCharacterRange(2, 150).test(value)
                        : true;
                break;
        }

        if (!isValid) {
            const formErrors = getFormErrors(
                errors,
                intl,
                name,
                errorMessageTranslationIdMap,
            );
            // if (aemPageNode) {
            multiStepErrorMessageEvent(
                TOOL_SUBSCRIBE,
                `Error: ${TOOL_SUBSCRIBE}`,
                formErrors.split('|'),
            );

            // analyticsAdvisorMultiStepEvent(
            //     {
            //         label: TOOL_SUBSCRIBE,
            //         action: SELF_SERVICE_ERROR,
            //         AEMNodePath: aemPageNode,
            //         value: `Start: ${TOOL_SUBSCRIBE}`,
            //         result: '',
            //         audience: urlSlug,
            //         pageName: `${urlSlug} > ${aemPageNode
            //             .split('/')
            //             .pop()}-Subscribe-Error`,
            //     },
            //     {
            //         name: TOOL_SUBSCRIBE,
            //         errorList: formErrors,
            //     },
            // );
            // }
        }

        setErrors({ ...errors, [name]: !isValid });
    };

    const handleInputFocus = () => {
        if (isFormTouched.current) return;
        pageLoadStartedEvent(
            'plan-sponsor-company > home-Subscribe-Start',
            'Home',
            'plan-sponsor-company',
        );
        // if (aemPageNode) {
        multiStepEvent({
            analyticsAppName: TOOL_SUBSCRIBE,
            eventName: SELF_SERVICE_START,
            name: `Start: ${TOOL_SUBSCRIBE}`,
            sessionSettings: 'Session',
        });
        pageLoadCompletedEvent();
        // analyticsAdvisorMultiStepEvent({
        //     label: TOOL_SUBSCRIBE,
        //     action: SELF_SERVICE_START,
        //     AEMNodePath: aemPageNode,
        //     value: `Start: ${TOOL_SUBSCRIBE}`,
        //     result: '',
        //     audience: urlSlug,
        //     pageName: `${urlSlug} > ${aemPageNode
        //         .split('/')
        //         .pop()}-Subscribe-Start`,
        // });
        // }
        isFormTouched.current = true;
    };
    const onRecaptchaChange = (token: string | null) => {
        if (token) {
            setIsRecaptchaValid(true);
        }
    };
    const onRecaptchaExpired = () => {
        setIsRecaptchaValid(false);
    };
    const handleSubmitClick = async () => {
        setIsLoading(true);
        try {
            const { requestEmailSent } = await subscribe(
                urlSlug,
                inputs[FIRST_NAME],
                inputs[LAST_NAME].trim(),
                inputs[JOB_TITLE].trim(),
                inputs[EMAIL].trim(),
                recaptchaRef.current?.getValue() as string,
                inputs[COMPANY_NAME].trim(),
            );
            setSubmitSuccess(requestEmailSent);
            clearForm();
            pageLoadStartedEvent(
                'plan-sponsor-company > home-Subscribe-Complete',
                'Home',
                'plan-sponsor-company',
            );
            // if (aemPageNode) {
            multiStepEvent({
                analyticsAppName: TOOL_SUBSCRIBE,
                eventName: SELF_SERVICE_COMPLETE,
                name: `Complete: ${TOOL_SUBSCRIBE}`,
                sessionSettings: 'Session',
            });
            pageLoadCompletedEvent();
            // analyticsAdvisorMultiStepEvent({
            //     label: TOOL_SUBSCRIBE,
            //     action: SELF_SERVICE_COMPLETE,
            //     AEMNodePath: aemPageNode,
            //     value: `Complete: ${TOOL_SUBSCRIBE}`,
            //     result: '',
            //     audience: urlSlug,
            //     pageName: `${urlSlug} > ${aemPageNode
            //         .split('/')
            //         .pop()}-Subscribe-Complete`,
            // });
            // }
            onClose();
        } catch (error) {
            setSubmitSuccess(false);
            setIsRecaptchaValid(false);
            console.error(error);
        } finally {
            setIsLoading(false);
            setIsRecaptchaValid(false);
        }
    };
    const handleCloseIconClick = () => {
        setIsRecaptchaValid(false);
        clearForm();
        onCloseIcon();
    };

    const clearForm = () => {
        setInputs({ ...formState });
        setErrors({ ...formStateError });
        isFormTouched.current = false;
    };

    const isSubmitButtonDisabled = Object.entries(inputs).some(
        ([key, value]) => {
            if (brandType === SITE_BRAND_RPI && key === COMPANY_NAME)
                return false;
            return (
                !value ||
                !isRecaptchaValid ||
                (errors as { [key: string]: boolean })[key]
            );
        },
    );

    return (
        <Modal open={open}>
            <div className={classes.root}>
                {isLoading && <Loader />}
                <IconButton
                    ref={closeBtnRef}
                    className={classes.closeBtn}
                    onClick={handleCloseIconClick}
                >
                    <CloseIcon className={classes.closeIcon} />
                </IconButton>
                <div className={`${classes.contentWrapper} formcontainer`}>
                    {!submitSuccess && (
                        <Alert
                            className={classes.error}
                            severity="error"
                            variant="outlined"
                            icon={<Clear />}
                        >
                            <FormattedMessage
                                id="subscribe.error"
                                values={{
                                    a: (chunk: string) => {
                                        return (
                                            <a href={`mailTo:${chunk}`}>
                                                {chunk}
                                            </a>
                                        );
                                    },
                                }}
                            />
                            <Typography
                                variant="body1"
                                component="span"
                            ></Typography>
                        </Alert>
                    )}
                    <div className={classes.title}>
                        <FormattedMessage id="subscribe.menuTitle" />
                    </div>
                    <div className={classes.subtext}>
                        <FormattedMessage id="subscribe.enterYourInfo" />
                    </div>
                    <div className={classes.headerRow}>
                        <div className={classes.column}>
                            <div className={classes.subsubtextTitle}>
                                <FormattedMessage id="subscribe.frequency" />
                            </div>
                            <div className={classes.subsubtext}>
                                <FormattedMessage id="subscribe.monthly" />
                            </div>
                        </div>
                        <div className={classes.column}>
                            <div className={classes.subsubtextTitle}>
                                <FormattedMessage id="subscribe.delivery" />
                            </div>
                            <div className={classes.subsubtext}>
                                <FormattedMessage id="subscribe.email" />
                            </div>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <TextField
                                value={inputs[FIRST_NAME]}
                                className={classes.textField}
                                error={errors[FIRST_NAME]}
                                label={intl.formatMessage({
                                    id: 'subscribe.firstName',
                                })}
                                helperText={intl.formatMessage({
                                    id:
                                        errorMessageTranslationIdMap[
                                            FIRST_NAME
                                        ],
                                })}
                                name={FIRST_NAME}
                                id={FIRST_NAME}
                                placeholder="First name"
                                onBlur={handleInputBlur}
                                onFocus={handleInputFocus}
                                onChange={handleInputChange}
                                type="first name"
                            />
                        </div>
                        <div className={classes.column}>
                            <TextField
                                value={inputs[LAST_NAME]}
                                className={classes.textField}
                                error={errors[LAST_NAME]}
                                label={intl.formatMessage({
                                    id: 'subscribe.lastName',
                                })}
                                helperText={intl.formatMessage({
                                    id: errorMessageTranslationIdMap[LAST_NAME],
                                })}
                                name={LAST_NAME}
                                id={LAST_NAME}
                                placeholder="Last name"
                                onBlur={handleInputBlur}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                type="last name"
                            />
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <TextField
                                value={inputs[JOB_TITLE]}
                                className={classes.textField}
                                error={errors[JOB_TITLE]}
                                label={intl.formatMessage({
                                    id: 'subscribe.jobTitle',
                                })}
                                helperText={intl.formatMessage({
                                    id: errorMessageTranslationIdMap[JOB_TITLE],
                                })}
                                name={JOB_TITLE}
                                id={JOB_TITLE}
                                placeholder="Job title"
                                onBlur={handleInputBlur}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                type="job title"
                            />
                        </div>
                        <div className={classes.column}>
                            <TextField
                                value={inputs[EMAIL]}
                                className={classes.textField}
                                error={errors[EMAIL]}
                                label={intl.formatMessage({
                                    id: 'subscribe.emailAddress',
                                })}
                                helperText={intl.formatMessage({
                                    id: errorMessageTranslationIdMap[EMAIL],
                                })}
                                name={EMAIL}
                                id={EMAIL}
                                placeholder="Email address"
                                onBlur={handleInputBlur}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                type="email"
                            />
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.column}>
                            {brandType === SITE_BRAND_CG ? (
                                <TextField
                                    value={inputs[COMPANY_NAME]}
                                    className={classes.textField}
                                    error={errors[COMPANY_NAME]}
                                    label={intl.formatMessage({
                                        id: 'subscribe.companyName',
                                    })}
                                    helperText={intl.formatMessage({
                                        id:
                                            errorMessageTranslationIdMap[
                                                COMPANY_NAME
                                            ],
                                    })}
                                    name={COMPANY_NAME}
                                    id={COMPANY_NAME}
                                    placeholder="Company name"
                                    onBlur={handleInputBlur}
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                    type="company name"
                                />
                            ) : null}
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <div
                                className={classes.recaptchaWrapper}
                                style={{ width: '300px' }}
                            >
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={GOOGLE_RECAPTCHA_V2_KEY_SITE}
                                    onChange={onRecaptchaChange}
                                    onExpired={onRecaptchaExpired}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <Button
                                data-analytics-placement="form submission:body"
                                data-analytics-label="trackLink:complete"
                                data-analytics-id="Subscribe submit button"
                                className={classes.submitButton}
                                onClick={handleSubmitClick}
                                disabled={isSubmitButtonDisabled}
                                variant="contained"
                            >
                                <FormattedMessage id="subscribe.submit" />
                            </Button>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.footerText}>
                            <FormattedMessage id="subscribe.allFields" />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SubscribeModal;
