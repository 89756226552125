export const parseJson = (json: string) => {
    let parsedJson;

    try {
        parsedJson = JSON.parse(json);
    } catch (e) {
        console.log('Unable to parse invalid json');
    }

    return parsedJson;
};
