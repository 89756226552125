import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 16,
        lineHeight: '150%',
        marginBottom: 27,

        '& > div': {
            color: theme.colors.purpleDark,
        },
    },
}));

interface InfoFieldProps {
    messageId: string;
    value: string | undefined;
}

const InfoField: FC<InfoFieldProps> = ({ messageId, value }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div>
                <FormattedMessage id={messageId} />
            </div>
            {value && <span>{value}</span>}
        </div>
    );
};

export default InfoField;
