import { FC } from 'react';
/* material */
import { makeStyles } from '@material-ui/core';

export type ContentLinkItem = {
    displayText: string;
    url: string;
};

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: 16,
        lineHeight: '24px',
        padding: '10px 0',

        [theme.breakpoints.up('sm')]: {
            fontSize: 18,
            lineHeight: '26px',
        },

        '& a': {
            color: theme.colors.blue,
            cursor: 'pointer',
            textDecoration: 'none',
        },

        '& a:hover': {
            textDecoration: 'underline',
        },
    },
}));

interface LinkListProps {
    items: ContentLinkItem[];
}

const LinkList: FC<LinkListProps> = ({ items }) => {
    const classes = useStyles();
    if (!items || !items.length) return null;

    return (
        <>
            {items.map((item: ContentLinkItem, idx: number) => {
                const { url, displayText } = item;
                return (
                    <div className={classes.root} key={idx}>
                        <a
                            data-analytics-id={displayText}
                            data-analytics-label="trackLink:anchorClick"
                            data-analytics-placement="Resources:center-right"
                            rel="noreferrer"
                            target="_blank"
                            href={url}
                        >
                            {displayText}
                        </a>
                    </div>
                );
            })}
        </>
    );
};

export default LinkList;
