import { FC } from 'react';

/* material */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: 16,
        margin: 0,
        padding: '10px 0 0 20px',

        [theme.breakpoints.up('sm')]: {
            fontSize: 22,
        },
    },
}));

interface ListProps {
    items: string[];
    listType?: 'ordered' | 'unordered';
}

const List: FC<ListProps> = ({ items, listType = 'ordered' }) => {
    const classes = useStyles();
    if (!items || !items.length) return null;

    const renderListItems = items.map((item: string, idx: number) => {
        return <li key={idx}>{item}</li>;
    });

    return listType === 'ordered' ? (
        <ol className={classes.root}>{renderListItems}</ol>
    ) : (
        <ul className={classes.root}>{renderListItems}</ul>
    );
};

export default List;
